
import BasicTablePage from '@/components/BasicPageLayout/BasicTablePage.vue'
import { defineComponent, nextTick, onMounted, reactive, ref, toRef, watch } from 'vue'
import usePagination from '@/utils/composables/usePagination'
import { UserRole } from '@/types/Role'
import { Buttons, Inputs, SpanMethod, TableColumns } from '@/types/BasicComponent'
import { formatTime, formatDate, getAreaListOfGx, getTableColumnsSummary } from '@/utils/common'
import dayjs from 'dayjs'
import BasicTablePrintAndExport from '@/components/BasicPageLayout/BasicTablePrintAndExport.vue'
import { useRouter } from 'vue-router'
import { getProductionList, ProductionListQueryParams } from '@/api/production'

export default defineComponent({
  components: {
    BasicTablePage,
    BasicTablePrintAndExport
  },
  setup() {
    const router = useRouter()
    const pagination = usePagination()
    const searchData = reactive<ProductionListQueryParams>({
      dataType: 1
    })
    const tableData = ref<AnyArray>([])
    const selectCount = ref(0)
    const gxAreaList = reactive<AnyArray>([])
    const exportRef = ref()
    const exportData = ref<AnyArray>([])

    const getTableData = async () => {
      const resp = await getProductionList(Object.assign({ pageNo: pagination.currentPage, pageSize: pagination.pageSize }, searchData))
      const respData = resp.data.data.records

      tableData.value = respData
      tableData.value.forEach((td, idx) => td.index = pagination.pageOffset + idx)

      pagination.total = resp.data.data.size
    }

    const getExportData = async () => {
      const resp = await getProductionList(Object.assign({ isExport: 1, pageNo: pagination.currentPage, pageSize: pagination.pageSize }, searchData))
      exportData.value = resp.data.data.records
    }

    const mySummaryMethod = (scope: AnyObject) => {
      let props = ['wood_num', 'wood_cost', 'volume']
      return getTableColumnsSummary(props, scope.columns, scope.data)
    }
    
    pagination.setCallback(getTableData)

    onMounted(() => {
      getTableData()
      getAreaListOfGx(gxAreaList) /*已在函数内部添加子项*/
    })

    const invoiceStatusList = ref<number[]>([])
    watch(invoiceStatusList, sList => {
      searchData.invoiceStatusStr = sList.join(',')
    })

    const searchInputs = reactive<Inputs>([
      {
        label: '货单号',
        type: 'input',
        placeholder: '货单号',
        model: toRef(searchData, 'orderNo'),
        width: '180px',
        clearable: true,
      }, {
        label: '胶合板厂',
        type: 'input',
        placeholder: '请输入胶合板厂',
        model: toRef(searchData, 'jhbcName'),
        width: '150px',
        clearable: true
      }, {
        label: '生产机主',
        type: 'input',
        placeholder: '请输入生产机主',
        model: toRef(searchData, 'xqjzName'),
        width: '150px',
        clearable: true
      }, {
        label: '支付状态',
        type: 'select',
        placeholder: '请选择支付状态',
        model: toRef(searchData, 'payStatus'),
        width: '150px',
        clearable: true,
        options: [
          { label: '未支付', value: 1 },
          { label: '已支付', value: 2 }
        ]
      }, {
        label: '开票状态',
        type: 'select',
        placeholder: '请选择开票状态',
        model: invoiceStatusList,
        width: '180px',
        clearable: true,
        multiple: true,
        options: [
          { label: '未开票', value: 1 },
          { label: '已开票', value: 2 },
          { label: '已申请开票', value: 3 }
        ]
      }, {
        label: '生产数据',
        type: 'select',
        placeholder: '请选择生产数据',
        model: toRef(searchData, 'dataType'),
        width: '150px',
        clearable: true,
        options: [
          { label: '全部', value: '' },
          { label: '有生产数据', value: 1 },
          { label: '无生产数据', value: 2 },
        ]
      }, {
        label: '下单时间',
        type: 'group',
        divider: '至',
        inputs: [
          {
            type: 'date',
            model: toRef(searchData, 'orderTimeStart'),
            clearable: false,
            placeholder: '开始日期',
            width: '150px',
          }, {
            type: 'date',
            model: toRef(searchData, 'orderTimeEnd'),
            clearable: false,
            placeholder: '结束日期',
            width: '150px'
          }
        ]
      }, {
        label: '开票时间',
        type: 'group',
        divider: '至',
        inputs: [
          {
            type: 'date',
            model: toRef(searchData, 'invoiceTimeStart'),
            clearable: false,
            placeholder: '开始日期',
            width: '150px',
          }, {
            type: 'date',
            model: toRef(searchData, 'invoiceTimeEnd'),
            clearable: false,
            placeholder: '结束日期',
            width: '150px'
          }
        ]
      }, {
        label: '销售时间',
        type: 'group',
        divider: '至',
        inputs: [
          {
            type: 'date',
            model: toRef(searchData, 'sellTimeStart'),
            clearable: false,
            placeholder: '开始日期',
            width: '150px',
          }, {
            type: 'date',
            model: toRef(searchData, 'sellTimeEnd'),
            clearable: false,
            placeholder: '结束日期',
            width: '150px'
          }
        ]
      }, {
        label: '区域',
        type: 'select',
        placeholder: '请选择区域',
        clearable: true,
        options: gxAreaList,
        model: toRef(searchData, 'areaCode')
      }
    ])

    const searchButtons = reactive<Buttons>([
      {
        label: '查询',
        icon: 'el-icon-search',
        onClick: getTableData
      }, {
        label: '导出',
        icon: 'el-icon-download',
        onClick: async () => {
          await getExportData()
          nextTick(() => exportRef.value.doExport())
        }
      }
    ])

    const tableColumns = reactive<TableColumns>([
      {
        prop: 'index',
        label: '序号',
        width: '80px',
        align: 'center'
      }, {
        prop: 'orderCode',
        label: '对应销售货单号',
        minWidth: '200px',
        preventFormat: true
      }, {
        prop: 'jhbName',
        label: '胶合板厂名称',
        minWidth: '200px'
      }, {
        prop: 'goodsName',
        label: '产成品',
        minWidth: '160px',
      }, {
        prop: 'xqjName',
        label: '采购方',
        minWidth: '150px'
      }, {
        prop: 'phone',
        label: '电话',
        minWidth: '150px',
        preventFormat: true
      }, {
        prop: 'idcard',
        label: '身份证号码',
        minWidth: '200px',
        preventFormat: true
      }, {
        prop: 'loadtime',
        label: '销售时间',
        minWidth: '120px',
        type: 'render',
        render: scope => formatTime(scope.row.loadtime)
      }, {
        prop: 'loadtime',
        label: '发车时间',
        minWidth: '120px',
        type: 'render',
        render: scope => formatTime(scope.row.loadtime)
      }, {
        prop: 'unloadtime',
        label: '卸车时间',
        minWidth: '120px',
        type: 'render',
        render: scope => formatTime(scope.row.unloadtime)
      }, {
        prop: 'paytime',
        label: '付款时间',
        minWidth: '120px',
        type: 'render',
        render: scope => formatTime(scope.row.paytime)
      }, {
        prop: 'woodNum',
        label: '采购原木（方）',
        minWidth: '160px',
      }, {
        prop: 'woodPrice',
        label: '采购单价',
        minWidth: '100px',
        //type: 'render',
        //render: scope => parseInt((parseInt(scope.row.unit_price) / 10).toString()) * 10
      }, {
        prop: 'woodCost',
        label: '采购总价',
        minWidth: '120px',
      }, {
        prop: 'buyTime',
        label: '采购时间',
        minWidth: '140px',
        type: 'render',
        render: scope => formatTime(scope.row.createdtime)
      }, {
        prop: 'volume',
        label: '生产单板数量（方）',
        minWidth: '180px',
      }
    ])

    return {
      getTableData,
      formatTime,
      formatDate,
      exportRef,
      searchInputs,
      searchData,
      searchButtons,
      tableColumns,
      pagination,
      tableData,
      exportData,
      UserRole,
      mySummaryMethod,
      selectCount
    }
  }
})
